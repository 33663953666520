export default {
  zh: {
    inputPlaceholder: '患者姓名/研究编号/手机号',
    search: '搜索',
    AddParticipant: '新增',
    patientStatus: {
      default: '请选择参与者状态',
      all: '全部',
      activation: '激活',
      suspend: '暂停',
      discontinue: '中止',
      complete: '完成',
    },
    inclusionORexclusion: {
      default: '纳入/排除',
      inclusion: '纳入',
      exclusion: '排除',
    },
    currentProcess: {
      all: '全部',
      register: '注册',
      preliminary: '初步筛选',
      baseline: '基线访视',
      fourth: '第四个月随访',
      final: '终期访访',
      termination: '中止研究',
    },
    form: {
      name: '患者姓名',
      ID: '研究编号',
      gender: '性别',
      birthDate: '出生年月',
      telephoneNumber: '手机号',
      joiningTime: '手机号',
      inclusionOrExclusion: '纳入/排除',
      status: '状态',
      researchProcess: '研究流程',
      operation: '操作',
    },
  },
  en: {
    inputPlaceholder: 'name/id/phone number',
    search: 'search',
    AddParticipant: 'Add Participant',
    patientStatus: {
      default: "Patient's status",
      all: 'All',
      activation: 'Activation',
      suspend: 'Suspend',
      discontinue: 'Discontinue',
      complete: 'Complete',
    },
    inclusionORexclusion: {
      default: 'Inclusion/Exclusion',
      inclusion: 'Inclusion',
      exclusion: 'Exclusion',
    },
    currentProcess: {
      all: 'All',
      register: 'Register',
      preliminary: 'Preliminary screen',
      baseline: 'Baseline visits',
      fourth: 'Fourth month follow-up',
      final: 'Final visit',
      termination: 'Termination of research',
    },
    form: {
      name: 'Name',
      ID: 'ID',
      gender: 'Gender',
      birthDate: 'Birth date',
      telephoneNumber: 'Telephone number',
      joiningTime: 'Joining time',
      inclusionOrExclusion: 'Inclusion/Exclusion',
      status: 'status',
      researchProcess: 'Research process',
      operation: 'operation',
    },
  },
};
