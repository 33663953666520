import preliminaryScreening from './preliminaryScreening';
export default {
  zh: {
    preliminaryScreening: {
      ...preliminaryScreening.zh,
    },
  },
  en: {
    preliminaryScreening: {
      ...preliminaryScreening.en,
    },
  },
};
