import router, { resetRouter } from './router';
import store from './store';
// import { Message } from 'element-ui';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import { isRelogin } from '@/service';
import cache from './plugins/cache';
import { SESSION_USER } from './constant';

NProgress.configure({ showSpinner: false });

const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (getToken() && cache.session.get(SESSION_USER)) {
    // to.meta.title && store.dispatch('settings/setTitle', to.meta.title);
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/redirect' });
      NProgress.done();
    } else if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      if (store.getters.menuRouters.length === 0) {
        isRelogin.show = true;
        resetRouter();

        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch('app/generateMenuRouters')
          .then((accessRoutes) => {
            isRelogin.show = false;
            router.addRoutes(accessRoutes); // 动态添加可访问路由表
            router.addRoute({ path: '*', redirect: '/404', hidden: true });
            // console.log(to);
            next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
          })
          .catch(() => {
            // console.log('出错了', err);

            store.dispatch('user/LoginOut').then(() => {
              next({ path: '/login' });
            });
          });
      } else {
        next();
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${encodeURIComponent(to.fullPath)}`); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
