import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n';
Vue.use(VueRouter);

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
export const dynamicRoutes = [
  // {
  //   path: '/workbench',
  //   role: ['evaluator'],
  //   component: () =>
  //     import(/* webpackChunkName: "layout" */ '@/Layout/Layout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         title: i18n.t('system.workbench'),
  //         icon: 'work',
  //       },
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "workbench" */ '@/views/WorkBench/WorkBench.vue'
  //         ),
  //     },
  //   ],
  // },
  {
    path: '/participant',
    role: ['evaluator'],
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/Layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'participants',
        meta: {
          title: i18n.t('system.ListOfParticipants'),
          icon: 'work',
        },
        component: () =>
          import(
            /* webpackChunkName: "participants" */ '@/views/Participants/Participants.vue'
          ),
      },
      {
        path: 'add',
        name: 'participantsAdd',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "participantsAdd" */ '@/views/ParticipantsAdd/ParticipantsAdd.vue'
          ),
      },
      {
        path: 'detail',
        name: 'participantsDetail',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "participantsDetail" */ '@/views/ParticipantsDetail/ParticipantsDetail.vue'
          ),
      },
    ],
  },
  {
    path: '/visit',
    role: ['evaluator'],
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/Layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'visit',
        meta: {
          title: i18n.t('system.visitList'),
          icon: 'work',
        },
        component: () =>
          import(/* webpackChunkName: "visit" */ '@/views/Visit/Visit.vue'),
      },
    ],
  },
  {
    path: '/materials',
    role: ['consumable_manager'],
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/Layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'materials',
        meta: {
          title: i18n.t('system.ListOfParticipants'),
          icon: 'work',
        },
        component: () =>
          import(
            /* webpackChunkName: "visit" */ '@/views/Materials/Materials.vue'
          ),
      },
    ],
  },
  {
    path: '/dataOutput',
    role: ['researcher'],
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/Layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'dataOutput',
        meta: {
          title: 'Data export list',
          icon: 'work',
        },
        component: () =>
          import(
            /* webpackChunkName: "visit" */ '@/views/dataOutput/index.vue'
          ),
      },
    ],
  },
  {
    path: '/education',
    name: 'educationLayout',
    component: () =>
      import(/* webpackChunkName: "layout" */ '@/Layout/Layout.vue'),
    children: [
      {
        path: '',
        name: 'education',
        meta: {
          title: i18n.t('system.visitList'),
          icon: 'work',
        },
        component: () =>
          import(
            /* webpackChunkName: "visit" */ '@/views/Education/Education.vue'
          ),
      },
    ],
  },
];

const staticRoutes = [
  {
    path: '',
    redirect: '/redirect',
  },
  {
    path: '/redirect',
    component: () =>
      import(/* webpackChunkName: "Home" */ '@/views/Home/Home.vue'),
  },
  {
    path: '/401',
    component: () =>
      import(/* webpackChunkName: "401" */ '@/views/error/401.vue'),
  },
  {
    path: '/404',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/error/404.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/Login/Login.vue'),
  },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_ROUTE_URL,
    routes: staticRoutes,
  });

const router = createRouter();
// 重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // the relevant part
}

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.VUE_APP_BASE_ROUTE_URL,
//   routes: staticRoutes,
// });

export default router;
