import { dynamicRoutes } from '@/router';
import auth from '@/plugins/auth';
const state = {
  menuRouters: [],
};

const mutations = {
  SET_MENUS_ROUTERS: (state, menuRouters = []) => {
    state.menuRouters = menuRouters;
    // console.log(state.menuRouters, '-----menuRouters-----');
  },
};

const actions = {
  generateMenuRouters({ commit }) {
    return new Promise((resolve) => {
      let rewriteRoutes = filterDynamicRoutes(dynamicRoutes);

      // 添加路由
      // router.addRoutes(rewriteRoutes);
      // rewriteRoutes.push();

      commit('SET_MENUS_ROUTERS', rewriteRoutes);
      resolve(rewriteRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  console.log(routes, '-----routes-----');
  const res = [];
  routes.forEach((route) => {
    if (route.role) {
      if (auth.hasPermiOr(route.role)) {
        res.push(route);
      }
    }
  });
  return res;
}
