import errorCodeEnum from './errorCode';

export const errorCode = errorCodeEnum;
/**
 * 缓存token
 */
export const SESSION_TOKEN = 'Authorization-Token';

/**
 * 缓存用户信息
 */
export const SESSION_USER = 'doctor-user';

/**
 * 缓存语言
 */
export const LANG = 'lang';

/**
 * 请求语言枚举
 */
export const REQUEST_LANG_HEADER = {
  zh: 'zh_CN',
  en: 'en_US',
  mla: 'ms_MY',
};
