export default {
  zh: {
    401: '认证失败，无法访问系统资源',
    403: '当前操作没有权限',
    404: '访问资源不存在',
    default: '系统未知错误，请反馈给管理员',
    downloadData: '正在下载数据，请稍候',
    downloadFileError: '下载文件出现错误，请联系管理员！',
    networkError: '后端接口连接异常',
    timeout: '系统接口请求超时',
    requestFailed: '系统请求接口异常',
    loginExpiration: '登录状态已过期，您可以继续留在该页面，或者重新登录',
    dealing: '数据正在处理，请勿重复提交',

    dataOver5M: '请求数据大小超出允许的5M限制，无法进行防重复提交验证。',
  },
  en: {
    401: 'Authentication failed, and the system resources cannot be accessed. Procedure',
    403: 'The current operation has no permission',
    404: 'The access resource does not exist',
    default:
      'The system has an unknown error. Please report the error to the administrator',
    downloadFileError: 'Error downloading file, please contact administrator!',
    downloadData: 'Please wait while downloading data',
    networkError: 'The back-end interface is improperly connected. Procedure',
    timeout: 'The system interface request timed out',
    requestFailed: 'The system request interface is abnormal. Procedure',
    loginExpiration:
      'The login status has expired, you can stay on the page or log in again',
    dealing: 'Be dealing with',
    dataOver5M:
      'The data size of the request exceeds the allowed limit of 5M, and anti-repeat submission verification cannot be performed.',
  },
  mla: {
    401: 'Pengesahan gagal dan sumber sistem tidak dapat diakses',
    403: 'Operasi semasa tidak mempunyai kebenaran',
    404: 'Sumber akses tidak wujud',
    default: 'Sistem ini tidak diketahui, sila laporkan kepada pentadbir',
    downloadFileError:
      'Terdapat ralat memuat turun fail, sila hubungi pentadbir!',
    downloadData: 'Muat turun data, sila tunggu',
    networkError:
      'Antara muka bahagian belakang disambungkan secara tidak normal',
    timeout: 'Permintaan antara muka sistem tamat masa',
    requestFailed: 'Antara muka permintaan sistem tidak normal',
    loginExpiration:
      'Status log masuk anda telah tamat tempoh, dan anda boleh kekal di halaman tersebut atau log masuk semula',
    dealing: 'Pemprosesan',
    dataOver5M:
      'Jika saiz permintaan melebihi had 5 MB yang dibenarkan, pengesahan penyerahan pendua tidak boleh dilakukan.',
  },
};
