import service from '@/service';

/**
 * 登录接口
 * @param {*} data
 * @returns Promise
 */
export const doctorPasswordLogin = (data) => {
  return service({
    url: '/login/web/doctorPasswordLogin',
    data,
    method: 'post',
    headers: {
      isToken: false,
    },
  });
};

/**
 * 退出登录接口
 * @param {*} data
 * @returns Promise
 */
export const doctorLogout = (data) => {
  return service({
    url: '/login/web/doctorLogout',
    data,
    method: 'post',
  });
};
