export default {
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Jelas',
    },
    datepicker: {
      now: 'Sekarang',
      today: 'Hari ini',
      cancel: 'Batal',
      clear: 'Jelas',
      confirm: 'OK',
      selectDate: 'Pilih tarikh',
      selectTime: 'Pilih masa',
      startDate: 'Tarikh Mula',
      startTime: 'Masa Mula',
      endDate: 'Tarikh Tamat',
      endTime: 'Masa tamat',
      prevYear: 'Tahun sebelumnya',
      nextYear: 'Tahun Depan',
      prevMonth: 'Bulan Sebelumnya',
      nextMonth: 'Bulan Depan',
      year: 'Tahun',
      month1: 'Januari',
      month2: 'Februari',
      month3: 'Mac',
      month4: 'April',
      month5: 'Mei',
      month6: 'Jun',
      month7: 'Julai',
      month8: 'Ogos',
      month9: 'September',
      month10: 'Oktober',
      month11: 'November',
      month12: 'Disember',
      week: 'Minggu',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Sel',
        wed: 'Rab',
        thu: 'Kha',
        fri: 'Jumaat',
        sat: 'Duduk',
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
    select: {
      loading: 'Loading',
      noMatch: 'No matching data',
      noData: 'No data',
      placeholder: 'Select',
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select',
      noData: 'No data',
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: '',
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input',
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue',
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum',
    },
    tree: {
      emptyText: 'No Data',
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'], // to be translated
      filterPlaceholder: 'Enter keyword', // to be translated
      noCheckedFormat: '{total} items', // to be translated
      hasCheckedFormat: '{checked}/{total} checked', // to be translated
    },
    image: {
      error: 'FAILED',
    },
    pageHeader: {
      title: 'Back', // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    },
    empty: {
      description: 'No Data',
    },
  },
};
