import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import network from '../plugin/network';
import system from '../plugin/system';
import data from '../plugin/data';
import pages from '../pages';
import form from '../form';
export default {
  ...zhLocale,
  network: {
    ...network.zh,
  },
  system: {
    ...system.zh,
  },
  data: {
    ...data.zh,
  },
  pages: {
    ...pages.zh,
  },
  form: {
    ...form.zh,
  },
};
