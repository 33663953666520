export default {
  zh: {
    login: '登录',
    cancel: '取消',
    logbackin: '重新登录',
    confirm: '确定',
    systemPrompt: '系统提示',
    title: '慢病管理中心-评估员',
    validate: {
      username: '请输入用户名',
      password: '请输入密码',
      captcha: '请输入验证码',
      input: '请输入',
      select: '请选择',
      choose: '请选择',
      date: '请选择日期',
    },
    logOutSuccessfully: '您已成功退出登录！',
    projectName: '马来西亚项目',
    loginName: '账号登录',
    logout: '退出登录',
    logoutTip: '确定是否退出登录',
    help: '帮助',
    hello: '你好',
    close: '关闭',
    success: '成功',
    materials: '耗材管理',
    export: '导出',
  },
  en: {
    login: 'Login',
    cancel: 'Cancel',
    logbackin: 'Log back in',
    systemPrompt: 'System prompt',
    confirm: 'Confirm',
    title: 'Chronic Disease Management Center',
    validate: {
      username: 'Please enter your username',
      password: 'Please enter password',
      captcha: 'Verification Code',
      input: 'please enter',
      select: 'please select',
      choose: 'please choose',
      date: 'please select a date',
    },
    logOutSuccessfully: 'You have successfully logged out!',
    // projectName: 'Projects in Malaysia',
    projectName: 'T-Manis',
    loginName: 'Account login',
    logout: 'Log out',
    logoutTip: 'Determine whether to log out',
    help: 'Help',
    hello: 'Hello',
    workbench: 'workbench',
    ListOfParticipants: 'List of participants',
    visitList: 'Follow-up management',
    search: 'search',
    reset: 'reset',
    signIn: 'Sign In',
    success: 'success',
    operation: 'Operation',
    close: 'Close',
    materials: 'Consumable management',
    export: 'export',
  },
  mla: {
    login: 'Log masuk',
    cancel: 'Batal',
    logbackin: 'Log masuk semula',
    systemPrompt: 'Gesaan sistem',
    confirm: 'Adakah awak pasti',
    validate: {
      username: 'Sila masukkan nama pengguna',
      password: 'Sila masukkan kata laluan anda',
      captcha: 'Sila masukkan kod pengesahan',
    },
    logOutSuccessfully: 'Anda telah berjaya log keluar!',
    projectName: 'Projek Malaysia',
    loginName: 'Log masuk akaun',
    logout: 'Daftar keluar',
    logoutTip: 'Tentukan sama ada untuk mendaftar keluar',
    help: 'Membantu',
    hello: 'Helo',
    close: 'Tutup',
    materials: 'Consumable management',
  },
};
