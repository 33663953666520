import { Message, MessageBox, Notification, Loading } from 'element-ui';
import I18n from '@/i18n';
let loadingInstance;

export default {
  // 消息提示
  msg(content) {
    Message.info(content);
  },
  // 错误消息
  msgError(content) {
    Message.error(content);
  },
  // 成功消息
  msgSuccess(content) {
    Message.success(content);
  },
  // 警告消息
  msgWarning(content) {
    Message.warning(content);
  },
  // 弹出提示
  alert(content) {
    MessageBox.alert(content, I18n.t('system.systemPrompt'));
  },
  // 错误提示
  alertError(content) {
    MessageBox.alert(content, I18n.t('system.systemPrompt'), { type: 'error' });
  },
  // 成功提示
  alertSuccess(content) {
    MessageBox.alert(content, I18n.t('system.systemPrompt'), {
      type: 'success',
    });
  },
  // 警告提示
  alertWarning(content) {
    MessageBox.alert(content, I18n.t('system.systemPrompt'), {
      type: 'warning',
    });
  },
  // 通知提示
  notify(content) {
    Notification.info(content);
  },
  // 错误通知
  notifyError(content) {
    Notification.error(content);
  },
  // 成功通知
  notifySuccess(content) {
    Notification.success(content);
  },
  // 警告通知
  notifyWarning(content) {
    Notification.warning(content);
  },
  // 确认窗体
  confirm(content, option = {}) {
    return MessageBox.confirm(content, I18n.t('system.systemPrompt'), {
      confirmButtonText: option.confirmButtonText || I18n.t('system.confirm'),
      cancelButtonText: option.cancelButtonText || I18n.t('system.cancel'),
      type: 'warning',
    });
  },
  // 提交内容
  prompt(content) {
    return MessageBox.prompt(content, I18n.t('system.systemPrompt'), {
      confirmButtonText: I18n.t('system.confirm'),
      cancelButtonText: I18n.t('system.cancel'),
      type: 'warning',
    });
  },
  // 打开遮罩层
  loading(content) {
    loadingInstance = Loading.service({
      lock: true,
      text: content,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    });
  },
  // 关闭遮罩层
  closeLoading() {
    loadingInstance.close();
  },
};
