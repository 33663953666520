export default {
  zh: {
    basicInformation: {
      title: '基本信息',
      participantName: '参与者姓名',
      dateOfBirth: '出生日期',
      patientId: '研究编号',
      assignedStudyNumber: '分配研究编号',
      gender: {
        txt: '性别',
        male: '男',
        female: '女',
      },
      phoneNumber: '手机号码',
      spouseParticipating: {
        txt: '您配偶是否也参与本项目吗？',
        yes: '是',
        on: '否',
      },
      associateYourSpouseInformation: {
        title: '关联您的配偶信息',
        txt: '姓名-研究编号-手机号',
      },
      height: '身高',
      weight: '体重',
      bmi: 'BMI',
      hba1c: 'HbA1c',
      fastingBloodGlucose: '空腹血糖（FPG）',
      systolicPressure: '收缩压',
      diastolicPressure: '舒张压',
      glomerularFiltrationRate: '肾小球滤过率（eGFR）',
      urinaryAlbuminCreatinineRatio: '尿白蛋白肌酐比（UACR）',
    },
    inclusionCriteria: {
      question1: {
        txt: '您是否被诊断为2型糖尿病？',
        yes: 'Yes',
        on: 'No',
      },
      question2: {
        txt: '您是否拥有智能手机并会使用？',
        yes: 'Yes',
        on: 'No',
      },
    },
    exclusionCriteria: {
      question1: {
        txt: '您是否正在使用任何口服降糖药（OGLD）或胰岛素进行治疗？',
        yes: '是',
        no: '否',
      },
      question2: {
        txt: '您是否怀孕？',
        yes: '是',
        no: '否',
      },
      question3: {
        txt: '您最近的HbA1c值是否低于8%？',
        yes: '是',
        no: '否',
      },
      question4: {
        txt: '您是否有终末期肾病（eGFR低于15 ml/min）？',
        yes: '是',
        no: '否',
      },
      question5: {
        txt: '您是否有视力障碍，无法自我管理糖尿病或使用智能手机？',
        yes: '是',
        no: '否',
      },
      question6: {
        txt: '您是否正在接受癌症治疗？',
        yes: '是',
        no: '否',
      },
      question7: {
        txt: '您是否正在使用免疫抑制剂？',
        yes: '是',
        no: '否',
      },
      question8: {
        txt: '您是否患有认知障碍或精神疾病相关的疾病（如痴呆、阿尔茨海默病、精神分裂症、躁狂症）？',
        yes: '是',
        no: '否',
      },
      question9: {
        txt: '该参与者是否符合条件安排筛查访视？',
        yes: '是',
        no: '否',
      },
    },
  },
  en: {
    basicInformation: {
      title: 'Basic information',
      participantName: 'Participant name',
      dateOfBirth: 'Date of birth',
      patientId: 'Patient ID',
      assignedStudyNumber: 'Assigned study number',
      gender: {
        txt: 'Gender',
        male: 'Male',
        female: 'Female',
      },
      phoneNumber: 'Phone number',
      spouseParticipating: {
        txt: 'Is your spouse also participating in this project?',
        yes: 'Yes',
        on: 'No',
      },
      associateYourSpouseInformation: {
        title: 'Associate your spouse information',
        txt: 'please select',
      },
      height: 'Height',
      weight: 'Weight',
      bmi: 'BMI',
      hba1c: 'HbA1c',
      fastingBloodGlucose: 'Fasting blood glucose',
      systolicPressure: 'Systolic pressure',
      diastolicPressure: 'Diastolic pressure',
      glomerularFiltrationRate: 'Glomerular filtration rate',
      urinaryAlbuminCreatinineRatio: 'Urinary albumin creatinine ratio',
    },
    inclusionCriteria: {
      question1: {
        txt: 'Have you been diagnosed with type 2 diabetes?',
        yes: 'Yes',
        on: 'No',
      },
      question2: {
        txt: 'Do you have a smartphone and know how to use it?',
        yes: 'Yes',
        on: 'No',
      },
    },
    exclusionCriteria: {
      question1: {
        txt: 'Are you using any oral hypoglycemic drugs (OGLD) or insulin for treatment?',
        yes: 'Yes',
        no: 'No',
      },
      question2: {
        txt: 'Are you pregnant?',
        yes: 'Yes',
        no: 'No',
      },
      question3: {
        txt: 'Has your recent HbA1c value been below 8%?',
        yes: 'Yes',
        no: 'No',
      },
      question4: {
        txt: 'Do you have end-stage renal disease (eGFR below 15 ml/min)?',
        yes: 'Yes',
        no: 'No',
      },
      question5: {
        txt: 'Do you have visual impairment, unable to self manage diabetes or use smart phones?',
        yes: 'Yes',
        no: 'No',
      },
      question6: {
        txt: 'Are you receiving cancer treatment?',
        yes: 'Yes',
        no: 'No',
      },
      question7: {
        txt: 'Are you using immunosuppressants?',
        yes: 'Yes',
        no: 'No',
      },
      question8: {
        txt: 'Do you suffer from cognitive impairment or mental illness related illnesses (such as dementia, Alzheimer’s disease, schizophrenia, mania)?',
        yes: 'Yes',
        no: 'No',
      },
      question9: {
        txt: 'Does the visitor meet the criteria to schedule a screening visit?',
        yes: 'Yes',
        no: 'No',
      },
      // question10: {
      //   txt: 'Have you scheduled a screening visit?',
      //   yes: 'Yes',
      //   no: 'No',
      // },
    },
  },
};
