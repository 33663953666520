import axios from 'axios';
import { Notification, MessageBox, Message, Loading } from 'element-ui';
import { saveAs } from 'file-saver';
import I18n from '@/i18n';
import store from '@/store';
import { getToken } from '@/utils/auth';
import cache from '@/plugins/cache';
import { tansParams, blobValidate } from '@/utils';
import { errorCode, LANG } from '@/constant';

// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

axios.defaults.headers['Content-Type'] =
  'application/x-www-form-urlencoded;charset=utf-8';

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = { show: false };

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_API_URL,
  // 超时
  timeout: 20000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers['lang'] = 'en_US' || cache.local.get(LANG);

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    if (getToken() && !isToken) {
      config.headers['authToken'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (
      !isRepeatSubmit &&
      (config.method === 'post' || config.method === 'put')
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === 'object'
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
      const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
      if (requestSize >= limitSize) {
        console.warn(`[${config.url}]: ` + I18n.t(`network.dataOver5M`));
        return config;
      }
      const sessionObj = cache.session.getJSON('sessionObj');
      if (
        sessionObj === undefined ||
        sessionObj === null ||
        sessionObj === ''
      ) {
        cache.session.setJSON('sessionObj', requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
        if (
          s_data === requestObj.data &&
          requestObj.time - s_time < interval &&
          s_url === requestObj.url
        ) {
          const message = I18n.t(`network.dealing`);
          console.warn(`[${s_url}]: ` + message);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON('sessionObj', requestObj);
        }
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    let msg = errorCode[code] || errorCode['default'];

    if (res.data.message) {
      msg = res.data.message;
    } else {
      msg = I18n.t(`network.${msg}`);
    }

    // 二进制数据则直接返回
    if (
      res.request.responseType === 'blob' ||
      res.request.responseType === 'arraybuffer'
    ) {
      return res.data;
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm(
          I18n.t(`network.loginExpiration`),
          I18n.t(`system.systemPrompt`),
          {
            confirmButtonText: I18n.t(`system.logbackin`),
            cancelButtonText: I18n.t(`system.cancel`),
            type: 'warning',
          }
        )
          .then(() => {
            isRelogin.show = false;
            store.dispatch('user/LoginOut').then(() => {
              location.href = '/';
            });
          })
          .catch(() => {
            isRelogin.show = false;
          });
      }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    } else if (code === 500) {
      Message({ message: msg, type: 'error' });
      return Promise.reject(new Error(msg));
    } else if (code === 601) {
      Message({ message: msg, type: 'warning' });
      return Promise.reject('error');
    } else if (code !== 200) {
      Notification.error({ title: msg });
      return Promise.reject('error');
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log('err' + error);
    let { message } = error;
    if (message == 'Network Error') {
      message = I18n.t(`network.networkError`);
    } else if (message.includes('timeout')) {
      message = I18n.t(`network.networkError`);
    } else if (message.includes('Request failed with status code')) {
      message = I18n.t(`network.requestFailed`);
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 });
    return Promise.reject(error);
  }
);

// 通用下载方法
export function download(filename, config) {
  downloadLoadingInstance = Loading.service({
    text: I18n.t(`network.downloadData`),
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });

  return service({
    responseType: 'blob',
    ...config,
  })
    .then(async (data) => {
      const isBlob = blobValidate(data);
      if (isBlob) {
        const blob = new Blob([data]);
        saveAs(blob, filename);
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg = errorCode[rspObj.code] || errorCode['default'];

        let msgStr = '';
        if (rspObj.message) {
          msgStr = rspObj.message;
        } else {
          msgStr = I18n.t(`network.${errMsg}`);
        }
        Message.error(msgStr);
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error(I18n.t('network.downloadFileError'));
      downloadLoadingInstance.close();
    });
}

export default service;
