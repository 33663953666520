export default {
  zh: {
    title: '基本信息',
    name: '姓名',
    mobileNumber: '手机号码',
    password: 'app密码',
    email: '电子邮件',
    gender: '性别',
    dateOfBirth: '出生日期',
    height: '身高',
    weight: '体重',
    genderOptions: {
      default: '请选择',
      man: '男',
      woman: '女',
    },
    register: '注册',
  },
  en: {
    title: 'Basic information',
    name: 'name',
    mobileNumber: 'Mobile number',
    password: 'app Password',
    email: 'Email',
    gender: 'Gender',
    dateOfBirth: 'Date of birth',
    height: 'Height',
    weight: 'Weight',
    genderOptions: {
      default: 'Please select',
      man: 'Male',
      woman: 'Female',
    },
    register: 'Register',
  },
};
