<template>
  <div class="dict-Div">
    <template v-for="(item, index) in options">
      <template v-if="values.includes(String(item.value))">
        <span :key="item.value" :index="index">{{ item.label }}</span>
        <!-- <el-tag
          v-else
          :disable-transitions="true"
          :key="item.value+index"
          :index="index"
          :type="item.raw.listClass == 'primary' ? '' : item.raw.listClass"
          :class="item.raw.cssClass"
        >
          {{ item.label }}
        </el-tag> -->
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DictTag',
  props: {
    options: {
      type: Array,
      default: null,
    },
    value: {
      type: [Number, String, Array, Boolean],
      default: '',
    },
  },
  computed: {
    values() {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value)
          ? this.value.map(String)
          : [String(this.value)];
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.dict-Div {
  display: inline;
}
</style>
