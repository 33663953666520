import elementMlaLocale from '../plugin/element-mla';
import network from '../plugin/network';
import system from '../plugin/system';
export default {
  ...elementMlaLocale,
  network: {
    ...network.mla,
  },
  system: {
    ...system.mla,
  },
};
