import ParticipantsAdd from './ParticipantsAdd';
import detail from './detail';
export default {
  zh: {
    detail: {
      ...detail.zh,
    },
    participantsAdd: {
      ...ParticipantsAdd.zh,
    },
  },
  en: {
    detail: {
      ...detail.en,
    },
    participantsAdd: {
      ...ParticipantsAdd.en,
    },
  },
};
