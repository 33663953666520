import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from './language/zh-CN';
import en from './language/en';
import mla from './language/mla';
import { LANG } from '@/constant';

const messages = {
  zh_CN: zh,
  en_US: en,
  ms_MY: mla,
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en_US', // set locale
  messages, // set locale messages
});

localStorage.setItem(LANG, 'en_US');

export default i18n;
