import Vue from 'vue';
import App from './App.vue';
import Element from 'element-ui';
import awaitTo from 'await-to-js';
import router from './router';
import store from './store';
import i18n from '@/i18n';
import 'normalize.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/icon.css';
import './permission';
import './styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import './icons'; // icon
import { delayRequest, validateForm, resetForm } from './utils';
import { disabledFutureDate } from './utils/validate';
import selectData from '@/utils/data.json';

import modal from './plugins/modal';
import Pagination from '@/components/Pagination';
import DictTag from '@/components/DictTag';

Vue.config.productionTip = false;
Vue.prototype.$awaitTo = awaitTo;
Vue.prototype.$delayRequest = delayRequest;
Vue.prototype.$validateForm = validateForm;
Vue.prototype.$resetForm = resetForm;
Vue.prototype.$modal = modal;
Vue.prototype.selectData = selectData;
Vue.prototype.$disabledFutureDate = disabledFutureDate;

Vue.use(Element, {
  zIndex: 1000,
  i18n: (key, value) => i18n.t(key, value),
});

Vue.component('Pagination', Pagination);
Vue.component('DictTag', DictTag);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
