import { doctorLogout, doctorPasswordLogin } from '@/api/system';
import { SESSION_TOKEN, SESSION_USER } from '@/constant';
import i18n from '@/i18n';
import cache from '@/plugins/cache';
import { MD5 } from '@/utils/crypto';

const state = {
  userInfo: cache.session.getJSON(SESSION_USER) || {},
  token: cache.session.get(SESSION_TOKEN),
  permissions: [],
};

const mutations = {
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo || {};
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions || [];
  },
};

const actions = {
  /**
   * 登录
   */
  Login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      let data = {
        ...loginData,
        password: MD5(loginData.password),
      };

      doctorPasswordLogin(data)
        .then((res) => {
          commit('SET_USER_INFO', res.data.userInfo);
          commit('SET_TOKEN', res.data.token);
          commit('SET_PERMISSIONS', res.data.permissions);
          cache.session.setJSON(SESSION_USER, res.data.userInfo);
          cache.session.set(SESSION_TOKEN, res.data.token);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /**
   * 退出登录
   */
  LoginOut({ commit }) {
    return new Promise((resolve) => {
      doctorLogout()
        .catch(() => {})
        .finally(() => {
          commit('SET_USER_INFO');
          commit('SET_TOKEN');
          commit('SET_PERMISSIONS');
          cache.session.clear();
          resolve(i18n.t('system.logOutSuccessfully'));
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
