import cache from '@/plugins/cache';
import { SESSION_TOKEN } from '@/constant';

export function getToken() {
  return cache.session.get(SESSION_TOKEN);
}

export function setToken(token) {
  return cache.session.set(SESSION_TOKEN, token);
}

export function removeToken() {
  return cache.session.remove(SESSION_TOKEN);
}
