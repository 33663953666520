import enLocale from 'element-ui/lib/locale/lang/en';
import network from '../plugin/network';
import system from '../plugin/system';
import data from '../plugin/data';
import pages from '../pages';
import form from '../form';
export default {
  ...enLocale,
  network: {
    ...network.en,
  },
  system: {
    ...system.en,
  },
  data: {
    ...data.en,
  },
  pages: {
    ...pages.en,
  },
  form: {
    ...form.en,
  },
};
