export default {
  zh: {
    signingStatus: '',
    SigningDate: '',
    assessor: '',
    edit: '',
    save: '保存',
    cancel: '取消',
    sign: '',
    preliminary: '初步筛选',
    baseline: '基线访视',
    fourth: '第四个月随访',
    final: '终期访访',
    termination: '中止研究',
    gender: {
      man: '男',
      woman: '女',
    },
  },
  en: {
    signingStatus: '',
    SigningDate: '',
    assessor: '',
    edit: '',
    save: '保存',
    cancel: '取消',
    sign: '',
    preliminary: 'Preliminary screen',
    baseline: 'Baseline visits',
    fourth: 'Fourth month follow-up',
    final: 'Final visit',
    termination: 'Termination of research',
    gender: {
      man: 'Male',
      woman: 'Female',
    },
  },
};
